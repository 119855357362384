<div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6" *ngIf="memberships.length > 0">
    <fc-button-primary (click)="addMembership()">
        <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
        <span class="hidden sm:flex">Add membership</span>
        <span class="flex sm:hidden">Add</span>
    </fc-button-primary>
</div>
<app-loader *ngIf="loading" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
<ng-container *ngIf="!loading">
    <div class="flex flex-col items-start self-stretch gap-6 py-8">
        <ng-container *ngIf="activeMemberships.length > 0">
            <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
                <div class="text-gray-900 font-normal text-xl">
                    Active memberships
                </div>
            </div>
            <div class="w-full rounded-lg overflow-y-hidden border border-gray-200 bg-white shadow-sm">
                <table class="w-full">
                    <thead>
                        <tr class="text-left">
                            <th class="py-4 px-6">Name</th>
                            <th class="py-4 px-6 w-60">Start date</th>
                            <th class="py-4 px-6 w-60">Status</th>
                        </tr>
                    </thead>
                    <tbody class="rounded-lg">
                        <ng-container *ngFor="let membership of activeMemberships; let last = last">
                            <tr class="{{ !last ? 'border-b border-gray-200' : '' }} {{ membership.status != 1 ? 'hover:bg-gray-50 cursor-pointer' : ''}}" [routerLink]="membership.status != 1 ? ['/student', studentId, 'membership', membership.id] : null">
                                <td class="py-2 px-6">
                                    <span class="whitespace-nowrap text-sm font-medium text-gray-900">{{ membership.name
                                        }}
                                    </span>
                                </td>
                                <td class="py-4 px-6">
                                    <div class="whitespace-nowrap text-sm text-gray-500">{{ membership.startDate|date:'dd-MM-yyyy' }}</div>
                                </td>
                                <td class="py-4 px-6">
                                    <div *ngIf="membership.status == 1" class="py-1 px-2 bg-gray-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                        <div class="text-center text-gray-700 text-[12px] font-medium leading-none">Pending</div>
                                    </div>
                                    <div *ngIf="membership.status == 2" class="py-1 px-2 bg-success-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                        <div class="text-center text-success-700 text-[12px] font-medium leading-none">Active</div>
                                    </div>
                                    <div *ngIf="membership.status == 3" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                        <div class="text-center text-error-700 text-[12px] font-medium leading-none">Inactive</div>
                                    </div>
                                    <div *ngIf="membership.status == 4 && membership.cancellationDate" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px]">
                                        <div class="text-center text-error-700 text-[12px] font-medium leading-none">Cancelling: {{membership.cancellationDate | date:'dd-MM-yyyy'}}</div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-container *ngIf="inactiveMemberships.length > 0">
            <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
                <div class="text-gray-900 font-normal text-xl">
                    Past memberships
                </div>
            </div>
            <div class="w-full rounded-lg overflow-hidden border border-gray-200 bg-white shadow-sm">
                <table class="w-full overflow-auto">
                    <thead>
                        <tr class="text-left">
                            <th class="py-4 px-6">Name</th>
                            <th class="py-4 px-6 w-60">Start date</th>
                            <th class="py-4 px-6 w-60">End date</th>
                            <th class="py-4 px-6 w-60">Status</th>
                        </tr>
                    </thead>
                    <tbody class="rounded-lg">
                        <ng-container *ngFor="let membership of inactiveMemberships; let last = last">
                            <tr class="{{ !last ? 'border-b border-gray-200' : '' }} hover:bg-gray-50 cursor-pointer" [routerLink]="['/student', studentId, 'membership', membership.id]">
                                <td class="py-2 px-6">
                                    <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ membership.name
                                        }}
                                    </div>
                                </td>
                                <td class="py-4 px-6">
                                    <div class="whitespace-nowrap text-sm text-gray-500">{{ membership.startDate|date:'dd-MM-yyyy' }}</div>
                                </td>
                                <td class="py-4 px-6">
                                    <div class="whitespace-nowrap text-sm text-gray-500">{{ membership.endDate|date:'dd-MM-yyyy' }}</div>
                                </td>
                                <td class="py-4 px-6 w-60">
                                    <div *ngIf="membership.status == 1" class="py-1 px-2 bg-gray-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                        <div class="text-center text-gray-700 text-[12px] font-medium leading-none">Pending</div>
                                    </div>
                                    <div *ngIf="membership.status == 2" class="py-1 px-2 bg-success-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                        <div class="text-center text-success-700 text-[12px] font-medium leading-none">Active</div>
                                    </div>
                                    <div *ngIf="membership.status == 3" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
                                        <div class="text-center text-error-700 text-[12px] font-medium leading-none">Inactive</div>
                                    </div>
                                    <div *ngIf="membership.status == 4 && membership.cancellationDate" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px]">
                                        <div class="text-center text-error-700 text-[12px] font-medium leading-none">Cancelling: {{membership.cancellationDate | date:'dd-MM-yyyy'}}</div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
    <div class="invisible p-4 w-[794px] fixed top-[100vh] ql-editor" #hiddenPdfDiv></div>
</ng-container>