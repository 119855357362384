import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Document } from 'src/app/models/document.model';
import { DocumentService } from 'src/app/services/document.service';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { MembershipService } from 'src/app/services/membership.service';
import { Membership } from 'src/app/models/membership.model';
import { StripeService } from 'src/app/services/stripe.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Discipline } from 'src/app/models/discipline.model';

@Component({
  selector: 'app-settings-memberships',
  templateUrl: './settings-memberships.component.html',
  styleUrls: ['./settings-memberships.component.scss']
})
export class SettingsMembershipsComponent implements OnInit {
  activeMemberships: Membership[] = [];
  archivedMemberships: Membership[] = [];
  billingCompleted: boolean = false;
  loadingStripe: boolean = false;
  loading: boolean = true;


  constructor(private membershipService: MembershipService, private stripeService: StripeService, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    forkJoin([
      this.membershipService.getMemberships(),
      this.membershipService.billingCompleted()
    ]).subscribe((results) => {
      const memberships = results[0];
      this.activeMemberships = memberships.filter((d) => !d.isArchived);
      this.archivedMemberships = memberships.filter((d) => d.isArchived);

      this.billingCompleted = results[1];

      this.loading = false;
    })
  }

  archiveMembership(id: number) {
    const membership = this.activeMemberships.filter(x => x.id == id)[0];

    if (membership.students.length > 0) {
      this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Cannot archive membership",
          content: `This membership cannot be archived because there are ${membership.students.length} active memberships attached to it. Please cancel all subscriptions before archiving. ${this.generateStudentsList(membership.students)}`,
          yesText: "Ok"
        }
      });

      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: "Archive membership",
        content: "Are you sure you want to archive this membership?",
        yesText: "Yes, archive it",
        noText: "Cancel",
        yesColourRed: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.membershipService.archiveMembership(id).subscribe(() => {
          const membershipToArchive = this.activeMemberships.find(membership => membership.id === id);
          if (membershipToArchive) {
            this.activeMemberships = this.activeMemberships.filter(membership => membership.id !== id);
            this.archivedMemberships.push(membershipToArchive);
          }
        });
      }
    });
  }

  restoreMembership(id: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: "Restore membership",
        content: "Are you sure you want to restore this memberhsip? Restoring this membership will not restore any memberships attached to students.",
        yesText: "Restore",
        noText: "Cancel",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.membershipService.restoreMembership(id).subscribe(() => {
          const membershipToRestore = this.archivedMemberships.find(membership => membership.id === id);
          if (membershipToRestore) {
            this.archivedMemberships = this.archivedMemberships.filter(membership => membership.id !== id);
            this.activeMemberships.push(membershipToRestore);
          }
        });
      }
    });
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }

  setupStripe() {
    this.loadingStripe = true;
    this.stripeService.createAccount().subscribe(result => {
      window.open(result, '_blank');
      this.loadingStripe = false;
    });
  }

  getDisciplines(disciplines: Discipline[]) {
    return disciplines.map(x => x.name).join(', ');
  }

  addMembership(): void {
    this.router.navigate(['create'], {relativeTo: this.route});
  }

  private generateStudentsList(students: string[]) {
    let result = '<ul class="list-disc px-6 mt-2">';
    students.forEach(student => {
      result += `<li>${student}</li>`
    });
    result += '</ul>';

    return result;
  }
}
