import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorModalComponent } from '@fc-lib';
import * as moment from 'moment-timezone';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { CheckInModalComponent } from 'src/app/components/modals/check-in-modal/check-in-modal.component';
import { NoteCreateModalComponent } from 'src/app/components/modals/note-create-modal/note-create-modal.component';
import { SessionInstance, SessionStudent, StudentCheckInCount, StudentNote } from 'src/app/models/session-instance.model';
import { StudentSearch } from 'src/app/models/student-search.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { GymService } from 'src/app/services/gym.service';
import { NoteService } from 'src/app/services/note.service';
import { SessionService } from 'src/app/services/session.service';
import { StudentService } from 'src/app/services/student.service';
import { ToasterService } from 'src/app/services/toaster.service';

const defaultPageSize: number = 5;

@Component({
  selector: 'app-session-id',
  templateUrl: './session-id.component.html',
  styleUrls: ['./session-id.component.scss']
})
export class SessionIdComponent implements OnInit {
  privateWeight = 0;
  visitsRequired = 0;
  currentPage = 1;
  lastPage = 1;
  pageSize: number = defaultPageSize;
  disciplines: string[] = [];
  disciplineIds: number[] | undefined;
  sessionStudents: SessionStudent[] = [];
  studentCheckInCounts: StudentCheckInCount[] = [];
  studentNotes: StudentNote[] = [];
  currentPageNotes: StudentNote[] = [];
  sessionInstance: SessionInstance | undefined;
  sessionInstanceId: number | undefined;
  sessionStart: moment.Moment | undefined;
  sessionEnd: moment.Moment | undefined;
  autoCompleteControl = new FormControl('');
  students: any[] = [];
  filteredStudents: Observable<StudentSearch[]>;
  selectedStudents: StudentSearch[] = [];
  loaded = false;
  gymName: string | undefined;
  constructor(private route: ActivatedRoute, private sessionService: SessionService, private studentService: StudentService, private gymService: GymService, private matDialog: MatDialog, private dialog: Dialog, private noteService: NoteService, private router: Router, private toasterService: ToasterService) {
    this.filteredStudents = this.autoCompleteControl.valueChanges.pipe(
      startWith(''),
      map(value => this.#filterStudents(value || '')),
    );
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.sessionInstanceId = +id;
      forkJoin([this.sessionService.getSessionInstance(+id), this.gymService.getGymAlerts(), this.gymService.getGym()])
      .subscribe(result => {
        const sessionInstance = result[0];
        const gymAlerts = result[1];
        const gym = result[2];
        this.sessionInstance = sessionInstance;
        this.sessionStart = moment(`${sessionInstance.date} ${sessionInstance.startTime}`);
        this.sessionEnd = moment(`${sessionInstance.date} ${sessionInstance.endTime}`);
        this.disciplines = sessionInstance.disciplines;
        this.disciplineIds = sessionInstance.disciplineIds;
        this.sessionStudents = sessionInstance.students;
        this.studentCheckInCounts = sessionInstance.studentCheckInCounts;
        this.studentNotes = sessionInstance.studentNotes;
        this.privateWeight = gymAlerts.gradingAlertSettings.privateWeight ?? 1;
        this.visitsRequired = gymAlerts.gradingAlertSettings.visitsRequired ?? 1;
        this.gymName = gym.name;
        if (this.visitsRequired <= 0) {
          this.visitsRequired = 1;
        }

        this.studentService.getSearchStudents(sessionInstance.disciplineIds).subscribe((result) => {
          this.students = result.filter(student => student.isActive);
          this.selectedStudents = result.filter(x => sessionInstance.students.map(x => x.id).indexOf(x.id) >= 0);
          this.#updateCurrentPageStudents();
          this.loaded = true;
        })
      });
    }
  }

  navigateToStudent(id: number) {
    this.router.navigate(['student', id]);
  }

  removeStudent(id: number) {
    if (this.sessionInstanceId) {
      this.sessionService.deleteCheckIn(this.sessionInstanceId, id).subscribe(() => {
        if (!this.sessionInstance?.isPrivate) {
          this.selectedStudents = this.selectedStudents.filter(x => x.id !== id);
        } else {
          let sessionStudent = this.sessionStudents.find(x => x.id == id);
          if (sessionStudent) {
            sessionStudent.isCheckedIn = false;
          }

          let checkInCount = this.studentCheckInCounts.find(x => x.studentId == id);
          if (checkInCount) {
            checkInCount.privateCount -= 1;
          }
        }
      });
    }
  }
  
  optionSelected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value as StudentSearch;
    this.autoCompleteControl.setValue(null);
    if (!this.selectedStudents.find(x => x.id == value.id)) {
      if (this.sessionInstanceId) {
        this.createCheckIn(this.sessionInstanceId, value);
      }
    }
  }

  createCheckIn(sessionInstanceId: number, student: StudentSearch, override: boolean = false) {
    this.sessionService.createCheckIn(sessionInstanceId, student.id, override).subscribe({
      next: () => {
        this.selectedStudents.push(student);
        this.dialog.open<string>(CheckInModalComponent, { data: { name: student.firstName }});
      },
      error: (error) => { 
        if (error.error.code === 'student_not_eligible') {
          const errorDialog = this.matDialog.open(ErrorModalComponent, { width: '600px', data: { title: 'Your membership plan doesn\'t cover this session', message: `Upgrade your membership plan to get access to this session. Let the team at ${this.gymName} know you'd like to upgrade.`, allowOverride: true } });

          errorDialog.afterClosed().subscribe(result => {
            if (result) {
              this.createCheckIn(sessionInstanceId, student, true);
            }
          });
        } else {
          this.toasterService.addToaster({ type: ToasterType.Error, message: 'Something went wrong' });
        }
      }
    });
  }

  checkIn(id: number) {
    if (this.sessionInstanceId) {
      this.sessionService.createCheckIn(this.sessionInstanceId, id).subscribe(() => {
        let sessionStudent = this.sessionStudents.find(x => x.id == id);
        if (sessionStudent) {
          sessionStudent.isCheckedIn = true;
        }

        let checkInCount = this.studentCheckInCounts.find(x => x.studentId == id);
        if (checkInCount) {
          checkInCount.privateCount += 1;
        }
      });
    }
  }

  isCheckedIn(id: number) {
    return this.sessionStudents.find(x => x.id === id)?.isCheckedIn;
  }

  getProgress(id: number) {
    const checkInCount = this.studentCheckInCounts.find(x => x.studentId === id);

    console.log(checkInCount);

    if (!checkInCount) {
      return 0;
    }

    const privateCount = checkInCount.privateCount * this.privateWeight;
    const groupCount = checkInCount.groupCount;
    const progress = ((privateCount + groupCount) / this.visitsRequired) * 100;

    return progress > 100 ? 100 : progress.toFixed(0);
  }

  getNoteDate(date: string) {
    return moment(date).format('dddd DD MMM');
  }

  getNoteDateElapsed(date: string) {
    return moment.tz(date, 'UTC').fromNow();
  }

  addNote() {
    const dialogRef = this.matDialog.open(NoteCreateModalComponent, {
      width: '400px',
      data: { students: this.selectedStudents }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.noteService.createNote({ sessionInstanceId: this.sessionInstanceId, studentId: result.student.id, content: result.content})
        .subscribe((note) => {
          this.studentNotes.unshift(note);
          this.currentPage = 1;
          this.#updateCurrentPageStudents();
        });
      }
    });
  }

  canGoNext() {
    return this.currentPage * this.pageSize < this.studentNotes.length;
  }

  next() {
    this.currentPage++;
    this.#updateCurrentPageStudents();
  }

  canGoBack() {
    return this.currentPage > 1;
  }

  back() {
    this.currentPage--;
    this.#updateCurrentPageStudents();
  }

  #updateCurrentPageStudents() {
    const size = this.pageSize
    const start = this.currentPage == 1 ? 0 : (this.currentPage - 1) * size;
    const end = this.currentPage == 1 ? size : (this.currentPage - 1) * size + size;
    this.currentPageNotes = this.studentNotes.slice(start, end);
    this.lastPage = Math.ceil(this.studentNotes.length / size);
  }

  #filterStudents(value: string): StudentSearch[] {
    if (value && typeof (value) == 'string') {
      const filterValue = value.toLowerCase();

      return this.students.filter(student => 
        (student.isActive && student.firstName.toLowerCase().includes(filterValue) || student.lastName.toLowerCase().includes(filterValue))
        && !this.selectedStudents.some(selected => selected.id === student.id));
    }

    return [];
  }
}
