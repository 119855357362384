import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Discipline } from 'src/app/models/discipline.model';
import { Document } from 'src/app/models/document.model';
import { Membership } from 'src/app/models/membership.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { DocumentService } from 'src/app/services/document.service';
import { MembershipService } from 'src/app/services/membership.service';

@Component({
  selector: 'app-settings-membership-create',
  templateUrl: './settings-membership-create.component.html',
  styleUrls: ['./settings-membership-create.component.scss']
})
export class SettingsMembershipCreateComponent implements OnInit {
  loaded: boolean = false;
  editing: boolean = false;
  membership: Membership | undefined = undefined;
  disciplines: Discipline[] = [];
  documentId: number | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private membershipService: MembershipService, private disciplineService: DisciplineService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.documentId = +id;
      this.editing = true;
      forkJoin({
        membership: this.membershipService.getMembership(+id),
        disciplines: this.disciplineService.getDisciplines()
      }).subscribe(result => {
        this.membership = result.membership;
        this.disciplines = result.disciplines;
        this.loaded = true;
      });
    }
    else {
      this.disciplineService.getDisciplines().subscribe(result => {
        this.disciplines = result.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        this.loaded = true;
      })
    }
  }
}
