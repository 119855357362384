import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Discipline } from 'src/app/models/discipline.model';
import { Document, DocumentType } from 'src/app/models/document.model';
import { Membership } from 'src/app/models/membership.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { DocumentService } from 'src/app/services/document.service';
import { MembershipService } from 'src/app/services/membership.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-membership-create-form',
  templateUrl: './membership-create-form.component.html',
  styleUrls: ['./membership-create-form.component.scss']
})
export class MembershipCreateFormComponent {
  @Input() membership: Membership | undefined = undefined;
  @Input() disciplines: Discipline[] = [];

  editing = false;
  submitted = false;
  loading = false;
  initialised = false
  hasDocument = false;
  membershipCreateForm!: FormGroup;
  documents: Document[] = [];
  paymentFrequencies = [
    {id: 1, name: 'Weekly'},
    {id: 2, name: 'Fortnightly'},
    {id: 3, name: 'Monthly'},
    {id: 4, name: '3-Monthly'},
    {id: 5, name: '6-Monthly'},
    {id: 6, name: 'Yearly'}
  ];

  private fightcloudAmountPercentage = 0.02;
  private debitAmountPercentage = 0.01;
  private debitAmountCents = 0.30;
  private cardAmountPercentage = 0.017;
  private cardAmountCents = 0.30;
  private taxAmount = 0.1;

  get paymentFrequency() {
    const paymentFrequency = this.membershipCreateForm.get('paymentFrequency')?.value;

    if (!paymentFrequency) {
      return;
    }

    return this.paymentFrequencies.find(x => x.id == paymentFrequency)?.name;
  }

  get paymentAmount() {
    const amount = +this.membershipCreateForm.get('paymentAmount')?.value ?? 0;

    if (this.paymentTaxInclusive) {
      return amount
    }

    return amount + (amount * this.taxAmount);
  }

  get tax() {
    return this.membershipCreateForm.get('tax')?.value;
  }

  get serviceAmountDebit() {
    return +this.membershipCreateForm.get('serviceAmountDebit')?.value;
  }

  get serviceAmountCard() {
    return +this.membershipCreateForm.get('serviceAmountCard')?.value;
  }

  get paymentTaxInclusive() {
    return this.membershipCreateForm.get('paymentTaxInclusive')?.value;
  }

  get chargeFees() {
    return this.membershipCreateForm.get('chargeFees')?.value;
  }

  constructor(private documentService: DocumentService, private membershipService: MembershipService, private toasterService: ToasterService, private router: Router, private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    const membership = this.membership;

    this.membershipCreateForm = new FormGroup({
      name: new FormControl<string>(membership?.name ?? '', Validators.required),
      description: new FormControl<string>(membership?.description ?? ''),
      disciplineIds: new FormControl<number[]>(membership?.versions[0].disciplines.map(x => x.id) ?? [], Validators.required),
      termsAndConditionsDocumentId: new FormControl<number | null>(membership?.versions[0].termsAndConditionsDocumentId ?? null),
      paymentFrequency: new FormControl<number>(membership?.versions[0].paymentFrequency ?? 1, Validators.required),
      paymentAmount: new FormControl<number>(membership?.versions[0].paymentAmount ?? 0.00, [Validators.required, Validators.min(1)]),
      paymentTaxInclusive: new FormControl<boolean>(membership?.versions[0].paymentTaxInclusive ?? true, Validators.required),
      serviceAmountDebit: new FormControl<number>(membership?.versions[0].serviceAmountDebit ?? 0.00, Validators.required),
      serviceAmountCard: new FormControl<number>(membership?.versions[0].serviceAmountCard ?? 0.00, Validators.required),
      chargeFees: new FormControl<boolean>(membership?.versions[0].serviceTaxInclusive ?? true, Validators.required),
    });

    if (membership) {
      this.hasDocument = !!membership.versions[0].termsAndConditionsDocumentId;
    }

    this.documentService.getDocuments().subscribe(documents => {
      this.documents = documents.filter(x => !x.isArchived && x.documentType == DocumentType.Membership);
    });

    if (this.membership) {
      this.editing = true;

      for (var field in this.membershipCreateForm.controls) {
        const control = this.membershipCreateForm.get(field);

        if (field !== 'description' && field !== 'termsAndConditionsDocumentId') {
          control?.disable();
          control?.clearValidators();
          control?.updateValueAndValidity();
        }
      }
    }

  }
  
  onSubmit(): void {
    this.submitted = true;
    if (this.membershipCreateForm.valid) {
      this.loading = true;
      if (this.editing) {
        this.membershipService.updateMembership(this.membership?.id!, this.membershipCreateForm.value).subscribe(result => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Membership updated'})
          this.router.navigate(['settings', 'memberships']);
          this.loading = false;
        })
      } else {
        this.membershipService.createMembership(this.membershipCreateForm.value).subscribe(result => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Membership created'})
          this.router.navigate(['settings', 'memberships']);
          this.loading = false;
        })
      }
    }
  }

  onInitialValueSet() {
    this.membershipCreateForm.markAsPristine();
  }

  formatDecimal(fieldName: string) {
    const field = this.membershipCreateForm.get(fieldName);

    if (!field) {
      return;
    }
    
    field.setValue(this.decimalPipe.transform(field.value, '1.2-2')?.replace(',', ''));
  }

  paymentAmountChange() {
    let paymentAmount = this.paymentAmount;

    if (!paymentAmount) {
      this.membershipCreateForm.get('serviceAmountDebit')?.setValue(0.00);
      this.membershipCreateForm.get('serviceAmountCard')?.setValue(0.00);
    }

    const debitAmount = this.calculateFees(paymentAmount, this.debitAmountCents, this.debitAmountPercentage, this.fightcloudAmountPercentage);// (((paymentAmount * this.debitAmountPercentage) + this.debitAmountCents) * 1.1) + (paymentAmount * this.fightcloudAmountPercentage);
    const cardAmount = this.calculateFees(paymentAmount, this.cardAmountCents, this.cardAmountPercentage, this.fightcloudAmountPercentage);

    this.membershipCreateForm.get('serviceAmountDebit')?.setValue(Math.round(debitAmount * 100) / 100);
    this.membershipCreateForm.get('serviceAmountCard')?.setValue(Math.round(cardAmount * 100) / 100);
  }

  private calculateFees(paymentAmount: number, stripeFixed: number, stripePercentage: number, fightCloudPercentage: number) {
    if (!this.chargeFees) {
      return (paymentAmount * stripePercentage) + (paymentAmount * fightCloudPercentage) + stripeFixed;
    }

    let totalPayment = paymentAmount;
    let netAmount = 0;

    do {
      totalPayment += 0.01;
      let stripeFee = (totalPayment * stripePercentage) + stripeFixed;
      let platformFee = totalPayment * fightCloudPercentage;
      netAmount = totalPayment - stripeFee - platformFee;
    } while (netAmount < paymentAmount);

    return totalPayment - paymentAmount;
  }
}
