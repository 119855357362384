<app-loader *ngIf="!loaded" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
<ng-container *ngIf="loaded">
    <div class="flex flex-col items-start self-stretch gap-6 py-8">
        <ng-container *ngIf="requiredDocs.length > 0">
            <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
                <div class="text-gray-900 font-normal text-xl">
                    Required documents
                </div>
            </div>
            <div class="w-full rounded-lg overflow-hidden border border-gray-200 bg-white shadow-sm">
                <table class="w-full overflow-auto">
                    <tbody class="rounded-lg">
                        <ng-container *ngFor="let document of requiredDocs; let last = last">
                            <tr class="{{ !last ? 'border-b border-gray-200' : '' }}">
                                <td class="flex py-4 px-6 items-center gap-3 self-stretch">
                                    <div class="w-10 h-10 p-2.5 justify-center bg-primary-100 rounded-full overflow-hidden">
                                        <document-outline-icon [size]="20" class="text-primary-600"></document-outline-icon>
                                    </div>
                                    <div>
                                        <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ document.name
                                            }}
                                        </div>
                                        <div class="whitespace-nowrap text-sm text-gray-500">Version {{ document.version }}
                                        </div>
                                    </div>
                                </td>
                                <td class="w-full py-4 px-6">
                                    <div class="text-sm text-error-500">Not yet signed</div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-container *ngIf="currentDocs.length > 0">
            <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
                <div class="text-gray-900 font-normal text-xl">
                    Current documents
                </div>
            </div>
            <div class="w-full rounded-lg overflow-x-auto border border-gray-200 bg-white shadow-sm">
                <table class="w-full overflow-auto">
                    <tbody class="rounded-lg">
                        <ng-container *ngFor="let document of currentDocs; let last = last">
                            <tr class="{{ !last ? 'border-b border-gray-200' : '' }}">
                                <td class="flex py-4 px-6 items-center gap-3 self-stretch">
                                    <div class="w-10 h-10 p-2.5 justify-center bg-primary-100 rounded-full overflow-hidden">
                                        <document-outline-icon [size]="20" class="text-primary-600"></document-outline-icon>
                                    </div>
                                    <div>
                                        <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ document.name
                                            }}
                                        </div>
                                        <div class="whitespace-nowrap text-sm text-gray-500">Version {{ document.version }}
                                        </div>
                                    </div>
                                </td>
                                <td class="min-w-40 py-4 px-6">
                                    <div class="text-sm text-gray-500">{{document.signedText}}</div>
                                </td>
                                <td class="p-4 flex justify-end gap-1 self-stretch text-gray-500">
                                    <div class="p-2.5 cursor-pointer" *ngIf="document.studentDocumentId" (click)="downloadStudentDocument(document)">
                                        <arrow-down-tray-outline-icon [size]="20"></arrow-down-tray-outline-icon>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-container *ngIf="previousDocs.length > 0">
            <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
                <div class="text-gray-900 font-normal text-xl">
                    Historic documents
                </div>
            </div>
            <div class="w-full rounded-lg overflow-hidden border border-gray-200 bg-white shadow-sm">
                <table class="w-full">
                    <tbody class="rounded-lg">
                        <ng-container *ngFor="let document of previousDocs; let last = last">
                            <tr class="{{ !last ? 'border-b border-gray-200' : '' }}">
                                <td class="flex py-4 px-6 items-center gap-3 self-stretch">
                                    <div class="w-10 h-10 p-2.5 justify-center bg-primary-100 rounded-full overflow-hidden">
                                        <document-outline-icon [size]="20" class="text-primary-600"></document-outline-icon>
                                    </div>
                                    <div>
                                        <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ document.name
                                            }}
                                        </div>
                                        <div class="whitespace-nowrap text-sm text-gray-500">Version {{ document.version }}
                                        </div>
                                    </div>
                                </td>
                                <td class="min-w-40 py-4 px-6">
                                    <div class="text-sm text-gray-500">{{document.signedText}}</div>
                                </td>
                                <td class="p-4 flex justify-end gap-1 self-stretch text-gray-500">
                                    <div class="p-2.5 cursor-pointer" *ngIf="document.studentDocumentId" (click)="downloadStudentDocument(document)">
                                        <arrow-down-tray-outline-icon [size]="20"></arrow-down-tray-outline-icon>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</ng-container>