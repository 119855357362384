import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable, map, startWith } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { getDateString } from 'src/app/lib/date-helpers';
import { Discipline } from 'src/app/models/discipline.model';
import { SessionInstance, SessionStudent } from 'src/app/models/session-instance.model';
import { StudentSearch } from 'src/app/models/student-search.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { SessionService } from 'src/app/services/session.service';
import { StudentService } from 'src/app/services/student.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-session-create-form',
  templateUrl: './session-create-form.component.html',
  styleUrls: ['./session-create-form.component.scss']
})
export class SessionCreateFormComponent implements OnInit {
  privateStudentError = false;
  submitted = false;
  editing = false;
  loading = false;
  defaultDate: Date = new Date();
  defaultStartTime = "08:00 AM";
  defaultEndTime = "09:00 AM";
  filteredStudents: Observable<StudentSearch[]>;
  selectedStudents: StudentSearch[] = [];
  autoCompleteControl = new FormControl('');
  sessionCreateForm = new FormGroup({
    disciplineIds: new FormControl<number[] | null>(null, Validators.required),
    isPrivate: new FormControl<boolean>(true, Validators.required),
    title: new FormControl<string | null>(null),
    description: new FormControl<string | null>(null),
    type: new FormControl<number>(1),
    frequency: new FormControl<number>(1),
    date: new FormControl<string | null>(''),
    startTime: new FormControl<string>("08:00:00"),
    endTime: new FormControl<string>("09:00:00"),
    occurances: new FormControl<number>(1, Validators.max(52)),
    students: new FormControl<SessionStudent[]>([]),
    trackProgress: new FormControl<boolean>(true)
  })
  frequencies = [{ id: 1, name: 'Weekly' }, { id: 2, name: 'Fortnightly' }];
  timePickerTheme: NgxMaterialTimepickerTheme = {
    clockFace: {
      clockHandColor: "#9E77ED"
    },
    dial: {
      dialBackgroundColor: "#9E77ED"
    },
    container: {
      buttonColor: "#9E77ED"
    }
  }
  
  @Input() sessionInstanceId: number | null = null;
  @Input() sessionInstance: SessionInstance | undefined;
  @Input() disciplines: Discipline[] = [];
  @Input() students: StudentSearch[] = [];
  @Input() date: string | undefined;
  @Input() hour: string | undefined;
  @Input() minute: string | undefined;

  get isPrivate() {
    return this.sessionCreateForm.get('isPrivate')?.value;
  }

  get type() {
    return this.sessionCreateForm.get('type')?.value;
  }

  get frequency() {
    const frequency = this.sessionCreateForm.get('frequency')?.value;

    if (!frequency) {
      return;
    }

    return this.frequencies.find(x => x.id == frequency)?.name;
  }

  get selectedDisciplines() {
    const disciplineIds = this.sessionCreateForm.get('disciplineIds')?.value;

    if (!disciplineIds) {
      return;
    }

    return this.disciplines.filter(x => disciplineIds.indexOf(x.id) >= 0).map(x => x.name);
  }

  constructor(private fb: FormBuilder, private sessionService: SessionService, private studentService: StudentService, private disciplineService: DisciplineService, private router: Router, private dialog: MatDialog, private toasterService: ToasterService) {
    this.filteredStudents = this.autoCompleteControl.valueChanges.pipe(
      startWith(''),
      map(value => this.#filterStudents(value || '')),
    );
  }

  ngOnInit(): void {
    if (this.sessionInstance) {
      this.editing = true;
      this.selectedStudents = this.students.filter(x => this.sessionInstance!.students.map(x => x.id).indexOf(x.id) >= 0);
      this.sessionCreateForm.get('disciplineId')?.disable();
      this.sessionCreateForm.patchValue(this.sessionInstance);
      this.defaultDate = new Date(this.sessionInstance.date);
      this.defaultStartTime = this.#convertFromMilitaryTime(this.sessionInstance.startTime);
      this.defaultEndTime = this.#convertFromMilitaryTime(this.sessionInstance.endTime);
    } else {
      const day = this.defaultDate.getDate().toString().padStart(2, '0');
      const month = (this.defaultDate.getMonth() + 1).toString().padStart(2, '0');
      const year = this.defaultDate.getFullYear().toString();
      this.sessionCreateForm.get('date')?.setValue(`${year}-${month}-${day}`);
    }

    if (this.date && this.hour && this.minute) {
      this.defaultDate = new Date(this.date);
      this.sessionCreateForm.get('date')?.setValue(this.date);
      const startTime = `${this.hour.padStart(2, '0')}:${this.minute.padStart(2, '0')}`;
      const endTime = `${(+this.hour+1).toString().padStart(2, '0')}:${this.minute.padStart(2, '0')}`;
      this.sessionCreateForm.get('startTime')?.setValue(`${startTime}:00`);
      this.sessionCreateForm.get('endTime')?.setValue(`${endTime}:00`);
      this.defaultStartTime = this.#convertFromMilitaryTime(startTime);
      this.defaultEndTime = this.#convertFromMilitaryTime(endTime);
    }
  }

  setPrivate(value: boolean) {
    if (value) {
      this.sessionCreateForm.get('title')?.clearValidators();
      this.sessionCreateForm.get('title')?.updateValueAndValidity();
      this.sessionCreateForm.get('isRecurring')?.clearValidators();
      this.sessionCreateForm.get('isRecurring')?.updateValueAndValidity();
      this.sessionCreateForm.get('frequency')?.clearValidators();
      this.sessionCreateForm.get('frequency')?.updateValueAndValidity();
      this.sessionCreateForm.get('occurances')?.clearValidators();
      this.sessionCreateForm.get('occurances')?.updateValueAndValidity();
    } else {
      this.sessionCreateForm.get('title')?.setValidators([Validators.required]);
      this.sessionCreateForm.get('title')?.updateValueAndValidity();
      this.sessionCreateForm.get('isRecurring')?.setValidators([Validators.required]);
      this.sessionCreateForm.get('isRecurring')?.updateValueAndValidity();
      this.sessionCreateForm.get('frequency')?.setValidators([Validators.required]);
      this.sessionCreateForm.get('frequency')?.updateValueAndValidity();
      this.sessionCreateForm.get('occurances')?.setValidators([Validators.required]);
      this.sessionCreateForm.get('occurances')?.updateValueAndValidity();
    }

    this.sessionCreateForm.get('isPrivate')?.setValue(value);
    this.sessionCreateForm.markAsDirty();
  }

  setType(value: number) {
    this.sessionCreateForm.get('type')?.setValue(value);
    this.sessionCreateForm.markAsDirty();
  }

  dateChange(date: MatDatepickerInputEvent<Date>) {
    if (date?.value) {
      this.sessionCreateForm.get('date')?.setValue(getDateString(date.value));
      this.sessionCreateForm.markAsDirty();
    }
  }

  timeChanged(field: string, time: any) {
    this.sessionCreateForm.get(field)?.setValue(this.#convertToMilitaryTime(time));
    this.sessionCreateForm.markAsDirty();
  }

  onSubmit() {
    this.submitted = true;
    if (this.sessionCreateForm.valid) {
      this.loading = true;
      if (this.sessionCreateForm.get('isPrivate')?.value === true) {
        if (this.selectedStudents.length == 0) {
          this.privateStudentError = true;
          this.loading = false;
          return;
        }
        let title = "Private Session";
        if (this.selectedStudents.length > 0) {
          title = `${title} - ${this.selectedStudents[0].firstName} ${this.selectedStudents[0].lastName}`;
        }
        this.sessionCreateForm.get('title')?.setValue(title);
      }
      this.sessionCreateForm.get('students')?.setValue(this.selectedStudents.map((x: any) => { return { id: x.id, isCheckedIn: true } }));
      if (this.editing && this.sessionInstanceId) {
        this.handleUpdateSession();
      } else {
        this.handleCreateSession();
      }
    }
  }

  handleUpdateSession() {
    if (this.sessionCreateForm.get('type')?.value == 2) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: { title: 'Update future sessions?', content: 'This session is part of a recurring schedule. Do you want to make these changes to all future sessions?', noText: 'Only this session', yesText: 'Yes, update all' }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.sessionService.updateSessionInstance(this.sessionInstanceId!, this.sessionCreateForm.value, result).subscribe({
          next: () => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Session updated'});
          this.router.navigate(['session', this.sessionInstanceId]);
          },
          error: () => {
            this.loading = false;
            this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to update session' });
          }
        });
      });
    } else {
      this.sessionService.updateSessionInstance(this.sessionInstanceId!, this.sessionCreateForm.value, true).subscribe({
        next: () => {
          this.toasterService.addToaster({ type: ToasterType.Success, message: 'Session updated' });
          this.router.navigate(['session', this.sessionInstanceId]);
        },
        error: () => {
          this.loading = false;
          this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to update session' });
        }
      });
    }
  }

  handleCreateSession() {
    this.sessionService.createSession(this.sessionCreateForm.value).subscribe({
      next: (result) => {
        this.toasterService.addToaster({ type: ToasterType.Success, message: 'Session created' });
        this.router.navigate(['session', result]);
      },
      error: () => {
        this.loading = false;
        this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to create session' });
      }
    });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value as StudentSearch;
    this.autoCompleteControl.setValue(null);
    if (!this.selectedStudents.find(x => x.id == value.id)) {
      this.selectedStudents.push(value);
    }
    this.sessionCreateForm.markAsDirty();
  }

  removeStudent(studentId: number) {
    this.selectedStudents = this.selectedStudents.filter(x => x.id !== studentId);
    this.sessionCreateForm.markAsDirty();
  }

  frequencyChange() {
    const frequency = this.sessionCreateForm.get('frequency')?.value;

    if (!frequency) {
      return;
    }

    this.sessionCreateForm.get('occurances')?.setValidators([Validators.required, Validators.max(frequency === 1 ? 52 : 26)]);
  }

  #convertToMilitaryTime(time: string) {
    const left = time.split(" ")[0];
    const right = time.split(" ")[1];

    if (right.toLowerCase() == 'am') {
      var hours = +left.split(':')[0];
      var minutes = +left.split(':')[1];

      if (hours == 12) {
        hours = 0;
      }

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    }

    var hours = +left.split(':')[0];
    hours = hours == 12 ? hours : hours + 12;
    var minutes = +left.split(':')[1];

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
  }

  #convertFromMilitaryTime(time: string) {
    var splitTime = time.split(':');

    var hours = +splitTime[0];
    var minutes = +splitTime[1];

    if (hours < 12) {
      if (hours == 0) {
        hours = 12;
      }
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} AM`;
    } else {
      if (hours != 12) {
        hours = hours - 12;
      }
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} PM`;
    }
  }

  #filterStudents(value: string): StudentSearch[] {
    if (value && typeof (value) == 'string') {
      const filterValue = value.toLowerCase();

      return this.students.filter(student => 
        (student.isActive && student.firstName.toLowerCase().includes(filterValue) || student.lastName.toLowerCase().includes(filterValue))
        && !this.selectedStudents.some(selected => selected.id === student.id));
    }

    return [];
  }
}
