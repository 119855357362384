<fc-grid [columns]="columns" [data]="documents" [actionTemplate]="actionTemplate">
</fc-grid>

<ng-template #actionTemplate let-row>
    <div class="flex justify-end gap-2">
        <fc-button-secondary [routerLink]="['edit', row.id]">
            View
        </fc-button-secondary>
    </div>
</ng-template>

<ng-template #versionTemplate let-row>
    <div class="w-full text-center">
        <span>{{ row.versions[0].version }}</span>
    </div>
</ng-template>